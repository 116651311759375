<template>
  <!-- 过户管理 -->
  <div class="page-card-demo page-info-content">
    <Tabs :active-name="formInline.transferStatus" :tabs-list="tabsList" @getTabName="getTabChange" />
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #goodsTwoCategoryName="{ row }">
          <div>{{ row.goodsTwoCategoryName }}/{{ row.goodsMaterialName }}/{{ row.goodsSpecName }}</div>
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Table from '@/components/Table.vue'
import Pagination from '@/components/Pagination2.vue'
import Tabs from '@/components/Tabs.vue'
import { tradeOrderTransferBillPagePlatTransferBill } from '@/api/shoppingMall'
export default {
  components: { Tabs, FormSearch, Table, Pagination },
  data() {
    return {
      tabsList: [
        { label: '全部过户单', name: '' },
        { label: '待支付', name: '00' },
        { label: '待商家审核', name: '02' },
        { label: '过户成功', name: '03' },
        { label: '已拒绝', name: '04' },
        { label: '已取消', name: '05' }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        transferStatus: ''
      },
      listData: [],
      loading: false,
      total: 0,
      searchFormItemArr: [
        { type: 'input', label: '过户单号', value: 'transferNo', width: '80px' },
        { type: 'input', label: '订单编号', value: 'orderNo', width: '80px' },
        { type: 'input', label: '原货主', value: 'sellName', width: '80px' },
        { type: 'input', label: '新货主', value: 'buyerName', width: '80px' },
        { type: 'input', label: '仓库名称', value: 'warehouseName', width: '80px' }
      ],
      itemData: [
        { label: '过户单号', prop: 'goodsTransferNo', width: 160 },
        { label: '关联订单号', prop: 'orderNo', width: 180, path: '/shoppingMall/orderDetails', pathParameter: 'orderId' },
        { label: '过户数量/单位', prop: 'transferWeight', dynamicUnit: 'unitMeasurement', type: 'weight', width: 120, unit: '吨' },
        { label: '原货主名称', prop: 'cmpName', width: 180 },
        { label: '新货主名称', prop: 'buyerCmpName', width: 180 },
        { label: '过户仓库', prop: 'warehouseName', width: 180 },
        { label: '状态', prop: 'transferStatus', width: 180, child: this.$store.getters.getDictionaryItem('transfer_bill_status') }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails }
      ]
    }
  },
  methods: {
    //  获取分页数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          transferStatus: '',
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      tradeOrderTransferBillPagePlatTransferBill(this.formInline, res => {
        this.listData = [...res.data.records]
        this.total = res.data.total
      })
    },
    // 获取当前tab
    getTabChange(val) {
      if (!val || val === '0') { this.formInline.transferStatus = '' } else { this.formInline.transferStatus = val }
      this.getdata()
    },
    // 查看详情
    viewDetails({ id }) {
      this.$router.push({
        path: '/shoppingMall/transferDetails',
        query: { id }
      })
    }
  }
}
</script>

<style scoped lang="scss">
:v-deep(.el-tabs__nav-wrap::after) {
  width: 0;
}
:v-deep(.el-tabs__active-bar) {
  display: none;
}
</style>
